import axios from 'axios';
//import * as XLSX from 'xlsx';
//import * as app_config from '../Auth/config.json';
const app_config = require('../Auth/config.json');
const XLSX = require('xlsx');

// Converting to arr from csv
function csvToArr(str) {
  const workbook = XLSX.read(str, { type: 'array' });
  const sheetName = workbook.SheetNames[0];
  const ws = workbook.Sheets[sheetName];
  const json = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: '' });
  return json;
}

const apiGatwayCall = async (_token, userData, service) => {
  const data = JSON.stringify(userData);
  const config = {
    method: 'post',
    url: `${app_config.url}${service}`,
    headers: {
      Authorization: `Bearer ${_token}`,
      'Content-Type': 'application/json',
    },
    data,
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => resolve(error));
  });
};

const apiGatwayCallExcel = async (_token, userData, service) => {
  const data = JSON.stringify(userData);
  const config = {
    method: 'post',
    url: `${app_config.url}${service}`,
    headers: {
      Authorization: `Bearer ${_token}`,
      'Content-Type': 'application/json',
    },
    responseType: 'arraybuffer',
    data,
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => resolve(error));
  });
};

const getAll = async (_token, value) => {
  const response = await apiGatwayCall(
    _token,
    { tableName: value },
    'gettable'
  );

  const { data } = response.data;
  return data;
};

export { csvToArr, getAll, apiGatwayCall, apiGatwayCallExcel };
