import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, IconButton } from '@mui/material';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
//import DatePicker from '@mui/lab/DatePicker';
import ClearIcon from '@mui/icons-material/Clear';
import { format } from 'date-fns';
import { utils } from '../../Data';

const useStyles = makeStyles({
  outlinedInput: {
    height: '2.175rem',
  },
});

function DateUI(props) {
  const classes = useStyles();
  const {
    defaultValue,
    minDate,
    maxDate,
    name,
    labelName,
    err,
    errMsg,
    clearAllowed,
    getDate,
    width,
  } = props;
  const [value, setValue] = useState(null);
  const dateName = name || '';

  const handleChange = (changedValue) => {
    setValue(changedValue);
    let onlyDate = changedValue.$d;
    changedValue = format(onlyDate, utils.dateFormat);
    getDate(changedValue, dateName);
  };

  const handleClear = () => {
    setValue(null);
    getDate(null, dateName);
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  function endAdornment() {
    return clearAllowed || clearAllowed === undefined ? (
      <IconButton onClick={handleClear}>
        <ClearIcon />
      </IconButton>
    ) : null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={labelName}
        value={value ? dayjs(value) : null}
        mask='__-__-____'
        inputFormat='dd-MM-yyyy'
        onChange={handleChange}
        maxDate={dayjs(maxDate)}
        minDate={minDate && dayjs(minDate)}
        inputProps={{ placeholder: 'Select Date - DD/MM/YYYY', readOnly: true }}
        InputProps={{
          className: classes.outlinedInput,
          endAdornment: endAdornment(),
        }}
        InputAdornmentProps={{
          position: 'start',
        }}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     size='small'
        //     error={err}
        //     helperText={errMsg}
        //     sx={{ width: width ?? '150px' }}
        //   />
        // )}
        onError={(err) => setError(err)}
        slotProps={{
          textField: {
            InputProps: {
              size :'small',
              helperText : { errMsg },
              sx : { width: width ?? '150px',
                     //color: '#1565c0',
                    //  borderRadius: '4px',
                    //  borderWidth: '2px',
                    //  borderColor: '#2196f3',
                    //  border: '2px solid',
               },
              variant: 'outlined',
            },
            
          } 
          
        }}
      />
    </LocalizationProvider>
  );
}

export default DateUI;
